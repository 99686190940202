<template>
  <v-app id="app">

    <v-app-bar app color="transparent" class="blurred-nav" flat>
        <v-container>
            <v-row align="center" justify="space-between">
                <v-col cols="auto" class="d-flex d-md-none"> <!-- Only show on small screens -->
                    <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
                </v-col>
                <v-col cols="auto" class="d-flex align-center d-md-none pr-8"> <!-- Only show on small screens, added right padding -->
                    <v-img :src="require('@/assets/logo_main.png')" max-height="50" min-width="50" class="mr-1"></v-img>
                    <router-link to="/" class="text-decoration-none">
                        <h1 class="text-h4 font-weight-bold" style="font-family: 'Montserrat', sans-serif; color: black !important;">allometrik</h1>
                    </router-link>
                </v-col>
                <v-col cols="auto" class="d-none d-md-flex align-center justify-center"> <!-- Only show on medium and larger screens -->
                    <v-img :src="require('@/assets/logo_main.png')" max-height="50" min-width="50" class="mr-1"></v-img>
                    <router-link to="/" class="text-decoration-none">
                        <h1 class="text-h4 font-weight-bold" style="font-family: 'Montserrat', sans-serif; color: black !important;">allometrik</h1>
                    </router-link>
                </v-col>
                <v-col cols="auto" class="d-none d-md-flex"> <!-- Only show on medium and larger screens -->
                    <v-btn text class="nav-link" @click="navigateOrScroll('services-section')" style="color: black;">Services</v-btn>
                    <v-btn text class="nav-link" @click="navigateOrScroll('process-section')" style="color: black;">Process</v-btn>
                    <v-btn text class="nav-link" @click="navigateOrScroll('about-me-section')" style="color: black;">About Us</v-btn>
                    <v-btn text to="/blog" class="nav-link" style="color: black;">Blog</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-app-bar>


    <v-navigation-drawer v-model="drawer" app temporary left>
        <v-list>
            <v-list-item @click="navigateOrScroll('services-section')">
                <v-list-item-title>Services</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateOrScroll('process-section')">
                <v-list-item-title>Process</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigateOrScroll('about-me-section')">
                <v-list-item-title>About Us</v-list-item-title>
            </v-list-item>
            <v-list-item to="/blog">
                <v-list-item-title>Blog</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>

    <router-view />

    <v-footer class="footer gradient-bg white--text">
      <v-container>
        <v-row justify="center" align="start" class="py-4">
          <v-col cols="12" sm="6" md="3" class="text-center text-sm-left mb-4 mb-md-0">
            <h3 class="text-h6 font-weight-bold mb-3">Allometrik</h3>
            <p class="mb-0">Innovating with AI and IoT solutions</p>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="text-center text-sm-left mb-4 mb-md-0">
            <h4 class="text-subtitle-1 font-weight-bold mb-3">Quick Links</h4>
            <a class="d-block mb-2 footer-link" @click="navigateOrScroll('services-section')">Services</a>
            <a class="d-block mb-2 footer-link" @click="navigateOrScroll('process-section')">Process</a>
            <a class="d-block mb-2 footer-link" @click="navigateOrScroll('about-me-section')">About Us</a>
            <router-link to="/blog" class="d-block mb-2 footer-link">Blog</router-link>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="text-center mb-4 mb-md-0">
            <h4 class="text-subtitle-1 font-weight-bold mb-3">Connect With Us</h4>
            <v-btn
              v-for="icon in icons"
              :key="icon.img"
              :href="icon.href"
              target="_blank"
              rel="noopener noreferrer"
              icon
              class="mx-2"
              color="white"
            >
              <v-icon>{{ icon.img }}</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="text-center text-md-right">
            <p class="mb-0">&copy; {{ new Date().getFullYear() }} Allometrik</p>
            <p class="mb-0">All rights reserved</p>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import Home from "./components/Home.vue";

export default {
  data: () => ({
    drawer: false, // Add drawer state
    icons: [
      { href: 'https://x.com/allometrik', img: 'fas fa-brands fa-x-twitter' },
      { href: 'https://www.linkedin.com/company/allometriktech/', img: 'mdi:mdi-linkedin' },
      { href: 'https://github.com/jovisaib', img: 'mdi:mdi-github' },
    ],
  }),
  name: "app",
  components: {
    Home
  },
  methods: {
      scrollToSection(sectionId) {
          const section = document.getElementById(sectionId);
          if (section) {
              section.scrollIntoView({ behavior: 'smooth' });
              this.drawer = false; // Close drawer after navigation
          }
      },
      navigateOrScroll(sectionId) {
          if (this.$route.path !== '/') {
              this.$router.push({ path: '/', query: { section: sectionId } });
          } else {
              this.scrollToSection(sectionId);
          }
      },
  },
  watch: {
      $route(to) {
          if (to.path === '/' && to.query.section) {
              this.$nextTick(() => {
                  this.scrollToSection(to.query.section);
              });
          }
      },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  color: #2c3e50;
}

.footer {
  width: 100%;
  padding: 40px 0;
}

.gradient-bg {
  background: linear-gradient(135deg, #6366F1, #8B5CF6, #EC4899);
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.v-btn.v-btn--icon {
  transition: transform 0.2s ease-in-out;
}

.v-btn.v-btn--icon:hover {
  transform: scale(1.2);
}

@media (max-width: 960px) {
  .footer .v-col {
    margin-bottom: 20px;
  }
}

.blurred-nav {
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.8);
}

.nav-link {
    color: #000;
    font-weight: bold;
    font-size: 1.2rem;
}

.v-app-bar {
    z-index: 10;
}

.footer .v-btn {
    opacity: 0.8;
    transition: opacity 0.2s ease-in-out;
}

.footer .v-btn:hover {
    opacity: 1;
}

.footer h3, .footer h4 {
    margin-bottom: 1rem;
}

.footer p {
    font-size: 0.9rem;
    line-height: 1.5;
}

.footer-link {
    color: black !important;
    text-align: left;
    font-size: 0.9rem;
    opacity: 0.8;
    transition: opacity 0.2s ease-in-out;
    text-decoration: none;
    cursor: pointer;
}

.footer-link:hover {
    opacity: 1;
}
</style>
